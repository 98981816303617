













































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class MapView extends Vue {}
